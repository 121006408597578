'use client';

import { useEffect } from 'react';
import { AppInsightService } from '@arkadium/modules/dist/lib/_shared/services/AppInsightService';
import { $configGamePageStore, ConfigGamePage } from '@/root/app/[locale]/games/[slug]/model';
import { useUnit } from 'effector-react';
import { initAppInsight } from '@/features/analytics/AI';

type TErrorProps = Readonly<{ error: Error & { digest?: string }; reset: () => void }>;

export default function GeneralError({ error, reset }: TErrorProps) {
    const configGamePage = useUnit($configGamePageStore) as ConfigGamePage;
    const asyncEffect = async () => {
        console.error(error);
        await aiTrackServerError(configGamePage, error);
    };
    useEffect(() => {
        asyncEffect();
    }, [error]);

    return (
        <div className="notFound">
            <h2>Something went wrong!</h2>
            <center>
                <button
                    onClick={
                        // Attempt to recover by trying to re-render the segment
                        () => reset()
                    }
                >
                    Try again
                </button>
            </center>
        </div>
    );
}

export async function aiTrackServerError(configGamePage: ConfigGamePage, error: Error) {
    await initAppInsight(true);
    const { adsFree, preroll } = configGamePage ?? {};
    const adBlockEnabled = preroll || !adsFree;
    const isUserLoggedIn: boolean = false; // TODO: add real value after login is implemented
    const isUserSubscribed: boolean = false; // TODO: add real value after login is implemented
    const info = {};
    const isErrorBoundary: boolean = true;
    AppInsightService.trackEvent('js-error', {
        error,
        info: JSON.stringify(info),
        page: window.location.href,
        adBlockEnabled,
        isUserLoggedIn,
        isUserSubscribed,
        type: isErrorBoundary ? 'Something went wrong' : 'common',
        arenaVersion: 'arena-evolution',
        buildVersion: process.env.buildid ?? 'dev',
    });
}
